jQuery(document).ready(function () {
	"use strict"

	jQuery('.grid-mlp a').on('mouseenter', function () {
		if(!jQuery(this).hasClass('caption'))
			jQuery(this).find('.card-content').stop(true, true).slideDown();
		else
			jQuery(this).find('.card-content').stop(true, true).animate({
				maxHeight: '360px'
			}, 750);
		// console.log('slide down');
	});
	jQuery('.grid-mlp a').on('mouseleave', function () {
		if(!jQuery(this).hasClass('caption'))
			jQuery(this).find('.card-content').stop(true, true).slideUp();
		else
			jQuery(this).find('.card-content').stop(true, true).animate({
				maxHeight: '20px'
			}, 750)
		// console.log('slide up');
	});

});