jQuery(document).ready(function() {
    "use strict"
    jQuery('.embedded-document-link').on('click', function(e) {
        var pagetitle = jQuery('title').html();
        var title = jQuery(this).data('title');
        var lang = jQuery(this).data('lang');
        var label = pagetitle + ' | ' + title + ' (' + lang + ')';
        if (typeof gtag == 'function') {
            gtag('event', 'Download', {
                'event_category': 'Embedded Documents',
                'event_action': 'Download',
                'event_label': label
            });
        }
    });
});